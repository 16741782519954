<template>
    <v-container>
        <div v-if="!loading">
            <div class="stack-icon my-5">
                <v-icon
                    color="almostblack"
                    size="120"
                    class="stack-icon-bottom"
                >
                    mdi-circle
                </v-icon>

                <v-icon
                    size="150"
                    color="yellow darken-2"
                    class="stack-icon-top"
                >
                    mdi-emoticon-excited
                </v-icon>
            </div>

            <h1 class="display-2 font-weight-thin text-center mb-5">
                Thank You
            </h1>

            <h2 class="display-1 font-weight-thin text-center mb-5">
                Your subscription has been<br>processed successfully!
            </h2>

            <v-row>
<!--                <v-col cols="12" md="12" class="text-center">-->
<!--                    <v-btn color="primary">See Your Premium Benefits</v-btn>-->
<!--                </v-col>-->
                <v-col cols="12" md="12" class="text-center">
                    <v-btn color="primary" to="/profile/subscriptions"><v-icon class="mr-1">mdi-ticket</v-icon>My Subscriptions</v-btn>
                </v-col>
            </v-row>


            <div class="text-center mt-10">

                <br>

            </div>
        </div>

        <div class="text-center" v-if="loading">
            <v-progress-circular
                color="primary"
                indeterminate
                size="150"
            >
            </v-progress-circular>
        </div>

    </v-container>
</template>

<style>
.stack-icon {
    display: flex;
}

.stack-icon-top {
    width: 100%;
    margin-left: -100%;
}

.stack-icon-bottom {
    width: 100%;
    padding-top: 1px;
}
</style>

<script>
import axios from "axios";
import {useTitle} from '@vueuse/core';
import config from "@/config";

export default {
    props: ['user'],

    data: () => ({
        loading: false,
        sessionId: null,
        session: null
    }),

    methods: {
        fetchCheckoutSession() {
            if (this.sessionId) {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(`${config.API_LOCATION}/subscription/session?sessionId=${this.sessionId}`, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.session = response.data;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        }
    },

    mounted() {
        useTitle('Thank You for Subscribing - myGMRS.com');

        this.sessionId = this.$route.query.session_id;
        // this.fetchCheckoutSession();
    }
}
</script>
