import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.loading)?_c('div',[_c('div',{staticClass:"stack-icon my-5"},[_c(VIcon,{staticClass:"stack-icon-bottom",attrs:{"color":"almostblack","size":"120"}},[_vm._v(" mdi-circle ")]),_c(VIcon,{staticClass:"stack-icon-top",attrs:{"size":"150","color":"yellow darken-2"}},[_vm._v(" mdi-emoticon-excited ")])],1),_c('h1',{staticClass:"display-2 font-weight-thin text-center mb-5"},[_vm._v(" Thank You ")]),_c('h2',{staticClass:"display-1 font-weight-thin text-center mb-5"},[_vm._v(" Your subscription has been"),_c('br'),_vm._v("processed successfully! ")]),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c(VBtn,{attrs:{"color":"primary","to":"/profile/subscriptions"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-ticket")]),_vm._v("My Subscriptions")],1)],1)],1),_c('div',{staticClass:"text-center mt-10"},[_c('br')])],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"150"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }